<template>
  <div class="schedule w-100">
    <div class="mt-2 mb-2">
      <div class="h3 ml-2">
        Сводное расписание
      </div>
    </div>
    <div class="schedule-container">
      <div class="container-header">
        <div class="container-navbar">
          <div class="h5 mb-0 text-white">
            Таблица
          </div>
          <div class="menu">
            <b-icon
              icon="list"
              font-scale="2"
              class="header-icon"
            />
          </div>
        </div>
        <div class="container-header-info">
          <div class="info-select">
            <b-button
              variant="outline-primary"
              size="sm"
              :disabled="!specialization || scheduleLoading"
              :pressed="pressQuantityBtn"
              class="info-select-btn"
              :type="$const.PRIMARY_BUTTON"
              @click="clickQuantityBtn"
            >
              Количество
            </b-button>
            <b-button
              variant="outline-primary"
              size="sm"
              :disabled="!specialization || scheduleLoading"
              :pressed="pressLoadBtn"
              class="info-select-btn"
              :type="$const.PRIMARY_BUTTON"
              @click="clickLoadBtn"
            >
              Загрузка
            </b-button>
            <v-select
              v-model="specialization"
              :reduce="spec => spec.id"
              :options="specializations"
              placeholder="Специализация"
              label="name"
              class="header-select"
            >
              <template #no-options>
                Ничего не найдено
              </template>
            </v-select>
          </div>
          <div class="info-label">
            <template v-if="schedules.length">
              <div
                v-for="item in isDoctorInfoSlots"
                :key="item.id"
                class="info"
                :class="item.class"
              >
                <span>{{ item.value }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <template v-if="scheduleLoading">
        <preloader style="margin-top: 20px" />
      </template>

      <template v-else>
        <div
          v-if="schedules.length && Object.keys(slotsByDate).length > 0"
          class="container-body"
        >
          <div class="container-body-date mt-1 mb-1">
            <span>{{ isMonthAndYear }}</span>
          </div>
          <div class="p-1">
            <table>
              <thead>
                <tr>
                  <th class="table-info time-info" />
                  <th
                    v-for="(date) in Object.keys(slotsByDate)"
                    :key="date"
                    class="table-info"
                    :class="{'table-info-weekend':formatData(date).includes('Сб') || formatData(date).includes('Вс')}"
                  >
                    {{ formatData(date) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(time, idx) in getTimes(15)"
                  :key="idx"
                >
                  <td class="table-info time-info">
                    {{ formatTime(time) }}
                  </td>
                  <td
                    v-for="(timeSlots, date) in slotsByDate"
                    :key="date"
                    class="td-element"
                    :class="pressQuantityBtn ?
                      getQuantitySlot(timeSlots[formattedTime(time)])
                      : getLoadSlot(timeSlots[formattedTime(time)])"
                    @click="onClickElement(timeSlots, formattedTime(time))"
                  >
                    <span class="p-0">{{ showInfoSlot(timeSlots[formattedTime(time)]) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          v-else-if="!schedules.length && specialization"
          class="mt-3 text-center text"
        >
          Пусто!
        </div>
        <div
          v-else-if="!specialization"
          class="mt-3 text"
        >
          Для загрузки сводного расписания необходимо выбрать
          <span class="text">специализацию.</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  DOCTOR_INFO_LOAD_SLOTS,
  DOCTOR_INFO_AMOUNT_SLOTS,
  DAYS_OF_WEEK,
  MONTHS,
} from '@/helpers/consts';
import {
  getMonth,
  getYear,
  getDaysInMonth,
  addDays,
  areIntervalsOverlapping,
} from '@evd3v/date-fns';
import Preloader from '@/components/Preloader';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'DoctorSchedule',
  components: {
    Preloader,
  },
  data() {
    return {
      isLoading: false,
      scheduleLoading: false,
      specialization: null,
      selectedClinic: null,
      pressQuantityBtn: true,
      pressLoadBtn: false,
      medicalInsuranceType: null,
      calendarHeight: '66vh',
      specializations: [],
      schedules: [],
      slots: [],
    };
  },
  computed: {
    formattedTime() {
      return (time) => new Date(time).toLocaleTimeString();
    },
    slotsByDate() {
      const data = {};
      const dates = this.getDates();
      const slots = this.getTimes(15);

      const formatedSlots = slots.slice(0, -1).map((slot, index) => {
        const endDate = new Date(slots[index + 1]);
        endDate.setSeconds(endDate.getSeconds() - 1);
        return {
          startDate: slot,
          endDate: endDate.toISOString(),
        };
      });

      dates.forEach((date) => {
        const dateString = new Date(date).toLocaleDateString();
        data[dateString] = {};
        slots.forEach((time) => {
          const timeString = new Date(time).toLocaleTimeString();
          data[dateString][timeString] = [];
        });

        formatedSlots.forEach((slot) => {
          const slotStartDate = new Date(slot.startDate);
          const slotEndDate = new Date(slot.endDate);

          const filteredSchedules = this.schedules.filter((schedule) => areIntervalsOverlapping(
            {
              start: new Date(schedule.startDate),
              end: new Date(schedule.endDate),
            },
            {
              start: new Date(date).setHours(slotStartDate.getHours(), slotStartDate.getMinutes()),
              end: new Date(date).setHours(slotEndDate.getHours(), slotEndDate.getMinutes()),
            },
          ));

          const timeString = new Date(slot.startDate).toLocaleTimeString();
          data[dateString][timeString] = filteredSchedules;
        });
      });

      return data;
    },
    isDoctorInfoSlots() {
      return this.pressQuantityBtn ? DOCTOR_INFO_AMOUNT_SLOTS : DOCTOR_INFO_LOAD_SLOTS;
    },
    isMonthAndYear() {
      return `${this.getMonthByIndex} ${getYear(new Date())}`;
    },
    getMonthByIndex() {
      const month = MONTHS.find((m) => m.id === getMonth(new Date()) + 1);
      return month.ru;
    },
  },
  watch: {
    specialization(e) {
      if (e) this.doctorsSheduleFetch();
      if (e === null) this.schedules = [];
    },
  },
  async created() {
    this.isLoading = true;
    this.specializations = await this.fetchSpecializations('');
    this.isLoading = false;
  },
  methods: {
    async fetchSpecializations(query) {
      const specializations = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);
      return specializations.sort((a, b) => a.name.localeCompare(b.name));
    },
    getTimes(minutes) {
      const timeArray = [];
      const startTime = new Date().setHours(0, 0, 0, 0);
      const endTime = new Date().setHours(23, 45, 0, 0);
      const currentTime = new Date(startTime);

      while (currentTime <= endTime) {
        timeArray.push(currentTime.toISOString());
        currentTime.setMinutes(currentTime.getMinutes() + minutes);
      }

      return timeArray;
    },
    getDates() {
      const result = [];
      const startDate = new Date();
      const endDate = addDays(startDate, getDaysInMonth(startDate));
      let currentDate = new Date(startDate);
      startDate.setHours(0, 0, 0, 0);

      while (currentDate < endDate) {
        result.push(currentDate);
        currentDate = addDays(currentDate, 1);
      }

      return result;
    },
    formatData(date) {
      const reversedDate = date.split('.').reverse().join('/');
      const dayOfMonth = new Date(reversedDate).getDate();
      const dayOfWeek = new Date(reversedDate).getDay();
      const shortenedDay = DAYS_OF_WEEK[dayOfWeek];

      return `${dayOfMonth} ${shortenedDay}`;
    },
    formatTime(time) {
      return (new Date(time).toLocaleTimeString()).slice(0, 5);
    },
    showInfoSlot(items) {
      if (this.pressQuantityBtn) {
        return `${items.length}`;
      }

      if (!items.length) return '0';

      let busySlot = [];
      busySlot = items.filter((item) => item.slotInfo.consultationId);

      if (!busySlot.length) {
        return `${items.length}`;
      }
      return `${busySlot.length}/${items.length}`;
    },
    async doctorsSheduleFetch() {
      if (!this.specialization) return;
      try {
        this.scheduleLoading = true;
        const schedules = await this.$store.dispatch(this.$types.DOCTOR_SCHEDULE_FETCH, {
          specializationId: this.specialization,
        });
        this.schedules = schedules;
      } catch (e) {
        console.log(e);
      } finally {
        this.scheduleLoading = false;
      }
      return this.schedules;
    },
    addMinutes(time, minutesToAdd) {
      const subtractedTime = new Date(`1970-01-01T${time}`);
      subtractedTime.setMinutes(subtractedTime.getMinutes() + minutesToAdd);
      return subtractedTime.toTimeString().slice(0, 8);
    },
    onClickElement(slots, time) {
      const prevTime = this.addMinutes(time, -15);
      const nextTime = this.addMinutes(time, 15);

      if (slots[time].length === 0) return;
      slots = [
        ...slots[prevTime],
        ...slots[time],
        ...slots[nextTime],
      ].filter((slot, index, array) => array.indexOf(slot) === index);

      this.openDoctorScheduleInfoModal(slots, time);
    },
    getQuantitySlot(slot) {
      switch (slot.length) {
        case 0:
          return 'white';
        case 1:
          return 'blue-0';
        case 2:
          return 'blue-1';
        case 3:
          return 'blue-2';
        case 4:
          return 'blue-3';
        case 5:
          return 'blue-4';
        case 6:
          return 'blue-5';
        case 7:
          return 'blue-6';
        case 8:
          return 'blue-7';
        default:
          return 'blue-8';
      }
    },
    getLoadSlot(slot) {
      let index = null;
      const numerator = slot.filter((item) => item.slotInfo.consultationId).length;
      const denominator = slot.length;
      if (numerator === 0) index = denominator;
      if (numerator && denominator) {
        index = (numerator / denominator) * 100;
      }

      if (index === 0) return 'white';
      if (index === 1) return 'light-grey';
      if (index === 100) return 'grey';
      if (index > 20 && index < 50) return 'yellow';
      if (index >= 50 && index <= 80) return 'green';
      if ((index > 0 && index < 20) || (index > 80 && index < 100) || index > 100) return 'red';
    },

    clickQuantityBtn() {
      this.pressQuantityBtn = true;
      this.pressLoadBtn = false;
    },
    clickLoadBtn() {
      this.pressLoadBtn = true;
      this.pressQuantityBtn = false;
    },
    openDoctorScheduleInfoModal(slots, selectedTime) {
      uiService.showModal(MODALS.DOCTOR_SCHEDULE_INFO_MODAL, {
        name: 'DoctorScheduleInfoModal',
        props: {
          slots,
          selectedTime,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vuecal__event {
  cursor: pointer;
  border: 1px solid #adadad;
  color: #adadad;
  font-size: 15px;
  font-weight: 500;
  &.open {
    background-color: #e5ffe5;
  }
  &.close {
    background-color: #f77f0f;
  }
}
.schedule {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.schedule-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.container-navbar {
  width: 100%;
  height: 70px;
  background-color: $blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  .header-icon {
    color: white;
    cursor: pointer;
  }
}
.container-header-info {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  height: 70px;
  padding: 0 10px;

  .info-select {
    display: flex;
    width: 60%;
    gap: 5px;

    &-btn {
      &:focus {
        background: rgb(104, 104, 219);
        color: white;
      }
    }
    .header-select {
      width: 33%;
    }
  }
  .info-label {
    display: flex;
    width: 40%;
  }
}

.info {
  height: 20px;
  width: 50px;
  border-radius: 3px;
  margin: 6px;
  text-align: center;
  outline: 1px solid blue;
  outline-offset: 4px;
  color: white;
}

.container-body {
  background-color: rgb(240, 234, 234);
  &-date {
    text-align: center;
    span {
      background-color: rgb(240, 234, 234);
      padding: 1px 5px;
      border: 1px solid rgb(207, 198, 198);
      border-radius: 3px;
    }
  }

  &-times {
    width: 26px;
    border: 1px solid rgb(207, 198, 198);
    min-inline-size: 60px;
    writing-mode: vertical-rl;
    transform: scale(-1)
  }
}
.table-info {
  background-color: #fdfbfb;
  outline: 1px solid white;
  color: $black-light;
  font-weight: 500;
  width: 20px;
}

.td-element {
  cursor: pointer;
  width: 20px;
  &:hover {
    transform: scale(1.1);
    outline: 2px solid white;
  }
}
.td-element:last-child {
  width: 16px;
}
table {
    width: 100%;
    background-color: rgb(221, 217, 217);
  th {
    font-size: 11px;
    background-color: white;
    text-align: center;
  }
  td {
    font-size: 11px;
    border: 1px solid rgb(248, 246, 246);
    text-align: center;
  }
  tr {
    color: #fff;
    font-weight: 700;
  }
}
thead, tbody tr {
    display: table;
    width: 100%;
}
tbody {
    display: block;
    width: 100%;
    max-height: 66vh;
    overflow-y: auto;
}

.time-info{
  width: 35px;
}
.table-info-weekend{
  background-color: #dadde0;
}
/*============== Цвета для загрузки =============*/
.light-grey {
  background-color: rgb(221, 217, 217);
  margin-left: auto;
}

.grey {
  background-color: rgb(182, 175, 175);
}

.green {
  background-color: rgb(104, 224, 110);
}

.yellow {
  background-color: rgb(188, 219, 9);
}

.red {
  background-color: rgb(228, 123, 81);
}

.blue {
  background-color: rgba(171, 233, 248, 0.993);
}
/*============== Цвета для количества =============*/
.white{
  background-color: rgb(248, 241, 241);
  color: #747171;
  font-weight: 500;
}
.blue-0{
  background-color: rgba(199, 216, 247, 0.993);
}
.blue-1{
  background-color: rgba(137, 187, 253, 0.993);
}
.blue-2{
  background-color: rgba(103, 154, 211, 0.993);
}
.blue-3{
  background-color: rgba(71, 123, 190, 0.993);
}
.blue-4{
  background-color: rgba(77, 74, 236, 0.993);
}
.blue-5{
  background-color: rgba(14, 66, 179, 0.993);
}
.blue-6{
  background-color: rgba(7, 28, 124, 0.993);
}
.blue-7{
  background-color: rgba(1, 18, 92, 0.993);
}
.blue-8{
  background-color: rgba(3, 13, 54, 0.993);
}
.text {
  font-size: 16px;
  font-weight: 400;
}

tbody {
  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;
}

tbody {
  padding-bottom: 30px;
  scrollbar-color: #448fda;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 17px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 17px;
    background: #e4f3ff;
  }

  &::-webkit-scrollbar-thumb {
    background: #a8a9aa;
    border-radius: 17px;
  }
}

</style>
